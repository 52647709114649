import {
  Breakpoint as BreakpointInterface,
  BreakpointValue as BreakpointValueInterface,
  BreakpointRespond as BreakpointRespondInterface,
} from '@/interfaces';

/** Breakpoints */
export const BP_VALUES: BreakpointValueInterface = {
  SM: 480,
  MD: 768,
  LG: 992,
  XL: 1400,
};

export const BP: BreakpointInterface = {
  SM: '480px',
  MD: '768px',
  LG: '992px',
  XL: '1400px',
};

const RESPOND_DOWN: BreakpointRespondInterface = Object.keys(BP).reduce(
  (breakpoints, bp) => {
    breakpoints[bp] = `@media screen and (max-width: ${BP_VALUES[bp] - 1}px)`;
    return breakpoints;
  },
  {}
);

const RESPOND_UP: BreakpointRespondInterface = Object.keys(BP).reduce(
  (breakpoints, bp) => {
    breakpoints[bp] = `@media screen and (min-width: ${BP[bp]})`;
    return breakpoints;
  },
  {}
);

export const MEDIA_QUERIES = {
  RESPOND_UP,
  RESPOND_DOWN,
};
