import { useEffect } from 'react';
import useSWR from 'swr';
import { userDataFetcher } from '@/lib/user';
import UserContext, {
  CurrentUserType,
  UserContextInterface,
} from '@/store/user/UserContext';
import { initialCurrentUser } from '@/store/user/UserProvider';
import useSafeContext from '@/hooks/utils/useSafeContext';
import { browserCookies } from '@/lib/bouqsCookies';
import { COOKIES } from '@/constants/cookies';
import { SF_URL } from '@/constants/env';
import { UseUserInterface } from './useUser.d';

const url = `${SF_URL}/current_user.json`;

const fetcherWithOptions = () =>
  userDataFetcher(url, {
    withCredentials: true,
    headers: {
      'X-Page-Referrer': document.referrer,
    },
  });

export default function useUser(): UseUserInterface {
  const { data: fetchedUserData } = useSWR<CurrentUserType>(
    url,
    fetcherWithOptions,
    // Avoid calling too many times current_user since the worker does it by us
    {
      errorRetryCount: 1, // try fetching again once if there's an error
      errorRetryInterval: 60000, // wait 1 minute to try again if there's an error
      fallbackData: initialCurrentUser,
    }
  );

  const state = useSafeContext<UserContextInterface>(UserContext);

  useEffect(() => {
    if (fetchedUserData?.checkout_id !== state?.currentUser?.checkout_id) {
      state.setCurrentUser(fetchedUserData);
      // TODO: When PROD-2432 + RUI-31 + any other Login flow related tickets are done
      // We are going to check less for current_user.json, so we'll consume the user data
      // from the cookie
      if (fetchedUserData?.auth_token) {
        const jwtToken = fetchedUserData.auth_token;
        // Setting the cookie so we can consume it server side if needed
        browserCookies.set(COOKIES.STOREFRONT_JWT_TOKEN, jwtToken);
      } else {
        browserCookies.remove(COOKIES.STOREFRONT_JWT_TOKEN);
      }
    }
  }, [fetchedUserData, state]);

  return { ...state, ...state.currentUser };
}
