import Cookies from 'universal-cookie';
import { ParsedUrlQuery } from 'querystring';
import { COOKIES, QP_COOKIES_OPTIONS } from '@/constants/cookies';
import { stringMap } from './bouqsCookies.d';

export const browserCookies = new Cookies();

export const serverCookies = (cookieString: string): Cookies => {
  const serverReader = new Cookies(cookieString);
  return serverReader;
};

const queryParamCookieMap = [
  {
    name: COOKIES.MCODE,
    param: 'mcode',
  },
  {
    name: COOKIES.PROMO_CODE,
    param: 'ccode',
  },
  {
    name: COOKIES.ORDER_PREFERENCES,
    params: {
      zipCode: 'zip_code',
      deliveryDate: 'delivery_date',
    },
  },
];

function diffParamCookieProps(
  cookieProps: stringMap,
  params: ParsedUrlQuery,
  map: stringMap
): stringMap | undefined {
  const diffEntries = Object.entries<string>(map)
    .map(([cp, qp]) => {
      const oldVal = cookieProps[cp];
      const newVal = params[qp];

      if (typeof newVal === 'undefined' || newVal === oldVal) {
        // don't update
        return [undefined, undefined];
      }

      // set new value
      return [cp, params[qp]];
    })
    .filter(entry => typeof entry[1] !== 'undefined');

  if (!diffEntries.length) {
    return;
  }

  const overwrites = Object.fromEntries(diffEntries);
  const newObject = {};

  Object.assign(newObject, cookieProps, overwrites);
  return newObject;
}

export const upsertCookies = (
  params: ParsedUrlQuery,
  cookies: stringMap,
  updateCookie: Cookies['set']
): boolean => {
  let isUpsert = false;
  queryParamCookieMap.forEach(paramCookie => {
    const cookieName = paramCookie.name;
    const cookieVal = cookies[cookieName];
    const paramVal = params[paramCookie.param];
    const paramsMap = paramCookie.params;

    if (typeof paramVal !== 'undefined' && paramVal !== cookieVal) {
      updateCookie(paramCookie.name, paramVal, QP_COOKIES_OPTIONS);
      isUpsert = true;
    } else if (paramsMap) {
      // this is an object cookie
      const cookieProps = cookieVal ?? {};
      const newObject = diffParamCookieProps(cookieProps, params, paramsMap);

      if (!newObject) {
        return;
      }

      updateCookie(cookieName, newObject, QP_COOKIES_OPTIONS);

      // an upsert happened
      isUpsert = true;
    }
  });

  return isUpsert;
};
