import { Event, Breadcrumb } from '@sentry/nextjs';

const filterErrors = (error: Event) => {
  return shouldIgnoreError(error) ? null : error;
}

const typeLoadErrorFailedValues = [
  'Failed to fetch',
  'NetworkError when attempting to fetch resource.',
  'Load failed',
];

const unhandledRejectionFailedValues = [
  'Non-Error promise rejection captured with value: ',
];

const urlsAllowedToFail = [
  'bouqs.attn.tv/unrenderedCreative',
];

const shouldIgnoreError = (error: Event): boolean => {
  const exception = error.exception?.values?.[0];
  const now = Date.now();

  const isTypeLoadError = exception?.type == 'TypeError' &&
    typeLoadErrorFailedValues.includes(exception.value as string);
  const isUnhandledRejectionError = exception?.type === 'UnhandledRejection' &&
    unhandledRejectionFailedValues.includes(exception.value as string);

  if (isUnhandledRejectionError) return true;
  if (!isTypeLoadError) return false;

  if (!error.breadcrumbs) return false;

  for (let i = error.breadcrumbs.length - 1; i >= 0; i--) {
    const breadcrumb = error.breadcrumbs[i];
    if (!breadcrumb) continue;

    // Check the last 5s of breadcrumbs
    if (breadcrumb.timestamp && now - breadcrumb.timestamp * 1000 > 5000) break;

    if (isErroneousBreadcrumb(breadcrumb)) return true;
  }

  return false;
}

const isErroneousBreadcrumb = (breadcrumb: Breadcrumb): boolean => {
  if ((breadcrumb.level !== 'error') ||
      (breadcrumb.category !== 'xhr' && breadcrumb.category !== 'fetch'))
    return false;

  const url = breadcrumb.data?.url as string | undefined;
  if (!url) return false;

  return !!urlsAllowedToFail.find(allowedUrl => url.includes(allowedUrl));
}

export default filterErrors;
