/**
 *
 * @description convert a nested object into a one level object
 * @example
 * const translation = {
 *  common: {
 *      greeting: 'Hey Bouqs!'
 *  }
 * }
 *
 * flatObject(translation) // { 'common.greeting': 'Hey Bouqs!' }
 */
export default function flatObject(toFlatObject: Record<string, any>): any {
  let oneLevelObject = {};
  for (const key in toFlatObject) {
    if (Object.prototype.hasOwnProperty.call(toFlatObject, key)) {
      const element = toFlatObject[key];
      oneLevelObject = {
        ...oneLevelObject,
        ...flatWithKeys(key, element),
      };
    }
  }

  return oneLevelObject;
}

function flatWithKeys(key: string, toFlatObject: any): any {
  let flattenedObject = {};
  if (isObject(toFlatObject)) {
    for (const toFlatKey in toFlatObject) {
      if (Object.prototype.hasOwnProperty.call(toFlatObject, toFlatKey)) {
        const chainedKey = `${key}.${toFlatKey}`;
        const element = toFlatObject[toFlatKey];
        if (isObject(element)) {
          flattenedObject = {
            ...flattenedObject,
            ...flatWithKeys(chainedKey, element),
          };
        } else {
          flattenedObject = {
            ...flattenedObject,
            [chainedKey]: element,
          };
        }
      }
    }
  } else {
    flattenedObject = {
      ...flattenedObject,
      [key]: toFlatObject,
    };
  }

  return flattenedObject;
}

function isObject(obj: any): boolean {
  return typeof obj === 'object' && Boolean(obj);
}
