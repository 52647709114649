import { Flex, Text, Link, Box } from '@thebouqs/ui';
import { BouqsLogo } from '@thebouqs/icons';
import { SF_URL } from '@/constants/env';
import { isBrowserSupported } from '@/utils/browserSupportUtils';

// This file and its dependencies must not use modern syntax

type BrowserCheckerProps = {
  children?: JSX.Element;
  trigger?: boolean;
};

/** Blocking browsers that we know won't work
 *  the reason not to use whitelist is to avoid blocking
 *  working browsers that we may not know.
 *
 *  As of now, webkit/603 and lower doesn't work
 *  Gecko [insert version] and lower doesn't work
 *  IE doesn't work (MSIE/ Trident)
 */

const BouqsLink = function () {
  return (
    <Link href={SF_URL} fontWeight="bold">
      Bouqs.com
    </Link>
  );
};

export function BrowserChecker({
  children,
  trigger,
}: BrowserCheckerProps): JSX.Element {
  // the trigger is needed because if there isn't a prop change in the client, this component won't re-render
  const deprecatedBrowser = trigger && !isBrowserSupported();

  if (deprecatedBrowser) {
    return (
      <Flex
        m="auto"
        p="6"
        direction="column"
        alignItems="center"
        justifyItems="center"
        color="text">
        <Box>
          <BouqsLogo width="sm" height="auto" color="primary" mt="40" mb="20" />
        </Box>
        <Text display="block" fontSize="2xl">
          {
            "You're using a browser that's outdated and not supported. Please upgrade it to a newer one to shop "
          }
          <BouqsLink />.
        </Text>
      </Flex>
    );
  }

  return children;
}
