import { IntlProvider } from 'react-intl';
import { defaultLocale } from '../languages.cjs';
import { I18nProviderProps } from './I18nProvider.d';
import messages from '../messages';

export default function I18nProvider(props: I18nProviderProps): JSX.Element {
  const { locale: localeProps, children } = props;
  const locale = localeProps ?? defaultLocale;

  return (
    <IntlProvider
      locale={locale}
      messages={messages[locale] || messages[locale]}>
      {children}
    </IntlProvider>
  );
}
