import * as React from 'react';
import StaticContext, { StaticMock } from './StaticContext';

export default function StaticProvider(
  props: JSX.ElementChildrenAttribute
): JSX.Element {
  return (
    <StaticContext.Provider
      value={StaticMock}
      {...props}></StaticContext.Provider>
  );
}
