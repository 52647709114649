import isBrowser from '@/utils/isBrowser';

// order matters, only one match allowed
// note Blink is fully supported so far
const knownBrowsers = [
  {
    pattern: /(Firefox|Gecko)\/(?<version>[\d]+)/,
    isVersionSupported: function (match) {
      return Number(match.groups.version) > 54;
    },
  },
  {
    pattern: /Chrome\/(?<version>[\d]+)/,
    isVersionSupported: function (match) {
      return Number(match.groups.version) > 27;
    },
  },
  {
    pattern: /(Safari)\/(?<version>[\d]+)/,
    isVersionSupported: function (match) {
      return Number(match.groups.version) > 603;
    },
  },
  {
    pattern: /(Webkit)\/(?<version>[\d]+)/,
    isVersionSupported: function (match) {
      return Number(match.groups.version) > 604;
    },
  },
  {
    pattern: /Trident|MSIE/,
    isVersionSupported: function () {
      return false;
    },
  },
];

export function isBrowserSupported(): boolean {
  let userAgent;

  if (isBrowser()) {
    userAgent = window?.navigator?.userAgent ?? '';
  } else {
    return true;
  }

  return isUserAgentSupported(userAgent);
}

// for testability, it's easier to just have a split function with only the logic and less context
export function isUserAgentSupported(userAgent: string): boolean {
  let matchFound;

  const knownBrowser = knownBrowsers.find(function (item) {
    const match = userAgent.match(item.pattern);
    if (match) {
      matchFound = match;
      return true;
    }
  });

  if (!knownBrowser) {
    // we don't know this UA, don't block it
    return true;
  }

  // we know this UA, check if it's supported
  return knownBrowser.isVersionSupported(matchFound);
}
