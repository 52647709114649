import axios, { AxiosRequestConfig } from 'axios';
import { CurrentUserResponseType } from '@/store/user/UserContext';

export const userDataFetcher = (
  url: string,
  options = {} as AxiosRequestConfig
): CurrentUserResponseType =>
  axios
    .get(url, {
      ...options,
    })
    .then(res => res.data);
