import React, { useEffect } from 'react';
import ConversionContext, {
  ConversionContextInterface,
} from './ConversionContext';
import { Props } from './ConversionProvider.d';

const ConversionProvider = ({ children }: Props): JSX.Element => {
  const urlParams = function () {
    return Object.fromEntries(new URLSearchParams(window.location.search));
  };

  const utmParams = function () {
    const utmParams = localStorage.getItem('utmParams') || '{}';
    return JSON.parse(utmParams);
  };

  const saveUTMParams = function (params: any) {
    const prevUTMParams = utmParams();

    return localStorage.setItem(
      'utmParams',
      JSON.stringify(Object.assign(prevUTMParams, params))
    );
  };

  const setFirstSessionParams = function () {
    const firstSessionParams = ['utm_source', 'utm_medium'];
    const params = urlParams();
    const prevUTMParams = utmParams();

    firstSessionParams.forEach(function (param) {
      const firstSessionParamName = param.replace('utm_', 'first_session_');

      if (params[param] && !prevUTMParams[firstSessionParamName]) {
        saveUTMParams({ [firstSessionParamName]: params[param] });
      }
    });
  };

  const storeUTMParams = function () {
    const params = urlParams();

    setFirstSessionParams();

    Object.keys(params).forEach(function (param) {
      if (param.startsWith('utm')) {
        const paramName = param.replace('utm_', '');
        saveUTMParams({ [paramName]: params[param] });
      }
    });
  };

  const storeMetaParams = function () {
    const params = urlParams();

    const FBparams = ['fbclid'];

    Object.keys(params).forEach(function (param) {
      if (FBparams.includes(param)) {
        saveUTMParams({ [param]: params[param] });
      }
    });
  };

  const storeMetaFBP = function () {
    const utm = utmParams();

    if (utm['fbp']) return;

    const version = 'fb';
    const subdomainIndex = window.location.host.startsWith('www.') ? '2' : '1';
    const creationTime = Date.now();
    const randomNumber = Math.round(Math.random() * 10000000000);
    const fbp = [version, subdomainIndex, creationTime, randomNumber].join('.');

    saveUTMParams({ fbp: fbp });
  };

  const clearUTMParams = function () {
    const utm = utmParams();
    const params = {};

    if (utm['fbp']) params['fbp'] = utm['fbp'];

    localStorage.setItem('utmParams', JSON.stringify(params));
  };

  useEffect(() => {
    storeUTMParams();
    storeMetaParams();
    storeMetaFBP();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const context: ConversionContextInterface = {
    utmParams,
    clearUTMParams,
  };

  return (
    <ConversionContext.Provider value={context}>
      {children}
    </ConversionContext.Provider>
  );
};

export default ConversionProvider;
