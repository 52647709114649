export const COOKIES = {
  STOREFRONT_JWT_TOKEN: '_bouqs_tkn',
  USER_ID: 'user_id',
  WINDOW_SESSION_ID: 'window_session_id',
  PROMO_CODE: 'ccode',
  MCODE: 'mcode',
  SHOW_SW_UPDATE_PROMPT: '_bouqs_show_sw_prompt',
  SHOW_LOGIN_PROMPT: '_bouqs_show_login_prompt',
  ORDER_PREFERENCES: '_bouqs_order_preferences',
};

export const QP_COOKIES_AGE = 3600 * 24;

export const QP_COOKIES_OPTIONS = {
  path: '/',
  maxAge: QP_COOKIES_AGE,
  httpOnly: false,
  secure: false,
  sameSite: true,
};
