import React, { useState } from 'react';
import UserContext, { CurrentUserType } from './UserContext';

export const initialCurrentUser: CurrentUserType = {
  logged_in: false,
  cart_quantity: 0,
  admin: false,
  user_id: null,
  presta_id: null,
  checkout_id: '',
  isPending: true,
};

export default function UserProvider(
  props?: JSX.ElementChildrenAttribute
): JSX.Element {
  const [currentUser, setCurrentUser] = useState(initialCurrentUser);

  return (
    <UserContext.Provider
      value={{
        currentUser,
        setCurrentUser,
      }}
      {...props}></UserContext.Provider>
  );
}
