// This file configures the intialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';
import { SENTRY_DSN } from '@/constants/env';
import { isBrowserSupported } from '@/utils/browserSupportUtils';
import filterErrors from '@/utils/sentry/filterErrors';

Sentry.init({
  dsn: SENTRY_DSN,
  // Need to disable this because Weblinc is on Sentry v9.0.0 and it does not support session tracking until Sentry v20.6.0
  // https://develop.sentry.dev/sdk/envelopes/
  // Enabling this results in a CORS error for envelope session requests
  // https://github.com/getsentry/sentry-javascript/issues/2859
  autoSessionTracking: false,
  initialScope: {
    tags: { appName: 'react ui browser' },
  },
  beforeSend: function (ev) {
    if (isBrowserSupported() === true) {
      return filterErrors(ev);
    }

    return null;
  },
  ignoreErrors: [
    'Unexpected token ...',
    "Unexpected token '<'",
    'SyntaxError: Unexpected token {',
    'Object.fromEntries is not a function',
  ],
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
});
