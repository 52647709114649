/* use this helper on conditional logic that runs on client/server */

function isBrowser(): boolean {
  if (typeof window !== 'undefined') {
    return true;
  }

  return false;
}

function getWindow(): Window | undefined {
  if (typeof window !== 'undefined') {
    return window;
  }
}

export { getWindow };
export default isBrowser;
