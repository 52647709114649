import { useContext, Context as CtxInterface } from 'react';

export default function useSafeContext<T = any>(Context: CtxInterface<T>): T {
  const context = useContext(Context);

  if (!context) {
    throw new Error('Context hook must be used within a valid provider');
  }

  return context;
}
