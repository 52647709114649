import { createContext } from 'react';
import {
  SegmentTrackProperties,
  SegmentPageProperties,
} from './AnalyticsProvider.d';

declare global {
  interface Window {
    analytics: {
      track: (string, SegmentTrackProperties) => void;
      page: (string, SegmentPageProperties) => void;
    };
  }
}

export type AnalyticsContextInterface = {
  track: (event: string, properties: SegmentTrackProperties) => void;
  page: (name: string, properties?: SegmentPageProperties) => void;
};

const AnalyticsContext = createContext<AnalyticsContextInterface>({
  track: () => null,
  page: () => null,
});

export default AnalyticsContext;
