import { COLORS, BP } from '@/constants/styles';
import { BouqsTheme } from '@/interfaces';

// ToDo: Move the following definitions to the bouqs theme and remove tempOverrides:
const tempOverrides = {
  radii: {
    md: '0.25rem',
  },
};

export const mainTheme: Partial<BouqsTheme> = {
  styles: {
    global: {
      'html, body': {
        padding: '0',
        margin: '0',
        fontFamily: 'proxima-nova, sans-serif',
        lineHeight: 'base',
      },
      html: {
        fontSize: '100%',
        MozOsxFontSmoothing: 'grayscale',
        WebkitFontSmoothing: 'antialiased',
      },
      body: {
        fontSize: 'md',
        color: 'text',
        overflowX: 'hidden',
      },
      button: {
        fontWeight: '400',
        fontSize: 'md',
        fontFamily: 'proxima-nova, sans-serif',
        borderStyle: 'solid',
      },
      'button:disabled': {
        pointerEvents: 'none',
      },
      'h1, h2, h3, h4, h5, h6, p, ol': {
        padding: '0',
        margin: '0',
      },
      '*': {
        boxSizing: 'border-box',
        fontWeight: 'inherit',
      },
      a: {
        textDecoration: 'none',
      },
      'a:hover': {
        textDecoration: 'underline',
      },
      img: {
        maxWidth: '100%',
        display: 'block',
      },
      'form *': {
        fontSize: 'lg',
      },
      'h1, h2': {
        marginBottom: '4',
      },
      'strong, b': {
        fontWeight: 'bold',
      },
      ul: {
        marginTop: '0',
        marginRight: '0',
        marginBottom: '4',
        marginLeft: '4',
        padding: '0',
      },
    },
  },
  breakpoints: {
    sm: BP.SM,
    md: BP.MD,
    lg: BP.LG,
    xl: BP.XL,
  },
  colors: {
    brand: {
      primary: COLORS.PRIMARY,
    },
    black15: COLORS.BLACK_15,
    black30: COLORS.BLACK_30,
    black50: COLORS.BLACK_50,
    black: COLORS.BLACK,
    border: COLORS.LIGHTER_GRAY,
    darkerGray: COLORS.DARKER_GRAY, // #72767a
    darkGray: COLORS.DARK_GRAY, // #a2a8ad
    inactive: COLORS.DARK_GRAY, // #a2a8ad
    lighterGray: COLORS.LIGHTER_GRAY, // #e6e9ed
    lightestGray: COLORS.LIGHTEST_GRAY, // #f7f9fc
    lightGray: COLORS.LIGHT_GRAY, // #d2d8de
    primary: COLORS.PRIMARY, // #144B3C
    primaryActive: COLORS.PRIMARY_ACTIVE, // #0A7256
    text: COLORS.DARKEST_GRAY, // #333333
    white15: COLORS.WHITE_15, // #ffffff
    white30: COLORS.WHITE_30, // #ffffff
    white50: COLORS.WHITE_50, // #ffffff
    white: COLORS.WHITE, // #ffffff
    success: COLORS.GREEN,
    mainBackground: COLORS.WHITE,
    error: COLORS.RED,
  },
  space: {
    // TODO: refactor so we can use Bouqs UI theme values instead
    none: 0,
    xxs: '0.12rem', //2px
    xs: '0.25rem', // 4px
    sm: '0.5rem', // 8px
    md: '1rem', // 16px
    lg: '1.5rem', // 24px
    xl: '2rem', // 32px
    '2xl': '2.5rem', // 40px
    '3xl': '3.36rem', // 62px
  },
  zIndices: [50, 100, 200, -1, -10],
  components: {
    // TODO: move these default styles to bouqs-ui
    Button: {
      baseStyle: {
        display: 'inline-flex',
        cursor: 'pointer',
        _hover: {
          textDecor: 'none',
        },
      },
    },
    // TODO: move Drawer button overriding styles to bouqs-ui
    Drawer: {
      variants: {
        left: () => ({
          closeButton: {
            right: 0,
            fontWeight: 'normal',
          },
        }),
        right: () => ({
          closeButton: {
            left: 0,
            fontWeight: 'normal',
          },
        }),
      },
    },
  },
  ...tempOverrides,
};
