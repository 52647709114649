// commonJS module to share with next.config.js

const languages = {
  en: 'en',
  es: 'es',
};

const defaultLocale = languages.en;

const localesArr = Object.keys(languages);

module.exports = { languages, defaultLocale, localesArr };
