import { GetServerSidePropsContext } from 'next';
import { ReactNode } from 'react';
import { ThemeContext } from '@emotion/react';
import {
  BouqsProvider,
  cookieStorageManager,
  localStorageManager,
  extendTheme,
  ThemeOverride,
} from '@thebouqs/ui';
import { mainTheme } from '@/styles/themes/mainTheme';

interface BouqsProps {
  cookies?: string;
  children: ReactNode;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const { breakpoints, zIndices, ...theme } = mainTheme;

const bouqsTheme: ThemeOverride = {
  ...(theme as ThemeOverride),
  zIndices: {
    '0': zIndices[0],
    '1': zIndices[1],
    '2': zIndices[2],
  },
};

// ChakraUI recomputes the theme to a CSS var map, this element will expose the raw theme like emotion expects it
const RawThemeExpose = ({ rawTheme }): JSX.Element => {
  // @ts-ignore
  const { components, ...foundations } = rawTheme;

  components; //ts-ignore didn't do the trick :(
  return (
    <ThemeContext.Consumer>
      {theme => {
        Object.assign(theme || {}, foundations || {});
        return null;
      }}
    </ThemeContext.Consumer>
  );
};

export const BouqsUI = ({ children, cookies }: BouqsProps): JSX.Element => {
  const extendedTheme = extendTheme(bouqsTheme);

  return (
    <BouqsProvider
      resetCSS={false}
      theme={extendedTheme}
      colorModeManager={
        cookies ? cookieStorageManager(cookies) : localStorageManager
      }>
      <RawThemeExpose rawTheme={extendedTheme} />
      {children}
    </BouqsProvider>
  );
};

export type ServerSideProps<T> = { props: T } | Promise<{ props: T }>;

export function getServerSideProps({
  req,
}: GetServerSidePropsContext): ServerSideProps<{ cookies?: string }> {
  return {
    props: {
      cookies: req.headers.cookie ?? '',
    },
  };
}
