import React, { useEffect } from 'react';
// apply polyfill for safari 11
import 'core-js/stable';
import 'intersection-observer';
import ReactDOM from 'react-dom';
import { AppProps, NextWebVitalsMetric } from 'next/app';
/**
 * Moved fonts here because there was a `styled-components` warning
 */
import '@/styles/fonts.css';
import UserProvider from '@/store/user/UserProvider';
import AnalyticsProvider from '@/store/analytics/AnalyticsProvider';
import ConversionProvider from '@/store/conversion/ConversionProvider';
import StaticProvider from '@/store/static/StaticProvider';
import FirstPaintProvider from '@/store/firstPaint';
import useLang from '@/hooks/useLang/useLang';
import I18nProvider from '@/i18n/I18nProvider';
import { SSRProvider } from '@react-aria/ssr';
import { BouqsUI } from '../BouqsUI';
import { NODE_ENV } from '@/constants/env';
import { BrowserChecker } from '@/components/BrowserChecker';
import isBrowser from '@/utils/isBrowser';

export function reportWebVitals(metric: NextWebVitalsMetric): void {
  if (NODE_ENV !== 'production') {
    switch (metric.name) {
      /*eslint-disable */
      case 'FCP':
        console.log('First Contentful Paint: ', metric.value, 'ms');
        break;
      case 'TTFB':
        console.log('Time to First Byte: ', metric.value, 'ms');
        break;
      case 'LCP':
        console.log('Largest Contentful Paint: ', metric.value, 'ms');
        break;
      case 'FID':
        console.log('First Input Delay: ', metric.value, 'ms');
        break;
      case 'CLS':
        console.log('Cumulative Layout Shift: ', metric.value, 'ms');
        break;
      /*eslint-enable */
    }
  }
}

const App = ({ Component, pageProps }: AppProps): JSX.Element => {
  const language = useLang();
  useEffect(() => {
    if (process.env.NODE_ENV !== 'production') {
      const { default: axe } = require('@axe-core/react');
      axe(React, ReactDOM, 1000);
    }
  });

  useEffect(() => {
    const handler = event => {
      if (!event.target) return;
      event.preventDefault();
    };
    window.addEventListener('unhandledrejection', handler);
    return () => {
      window.removeEventListener('unhandledrejection', handler);
    };
  }, []);

  const _isBrowser = isBrowser();

  return (
    <BouqsUI>
      <I18nProvider locale={language}>
        <UserProvider>
          <FirstPaintProvider>
            <SSRProvider>
              <BrowserChecker trigger={_isBrowser}>
                <ConversionProvider>
                  <AnalyticsProvider>
                    <StaticProvider>
                      <Component {...pageProps} />
                    </StaticProvider>
                  </AnalyticsProvider>
                </ConversionProvider>
              </BrowserChecker>
            </SSRProvider>
          </FirstPaintProvider>
        </UserProvider>
      </I18nProvider>
    </BouqsUI>
  );
};

export default App;
