import { createContext } from 'react';

export type StaticContextInterface = Record<string, any>;

export const StaticMock: StaticContextInterface = {
  data: {},
};

const StaticContext = createContext<StaticContextInterface>({ data: {} });

export default StaticContext;
