import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import isBrowser from '@/utils/isBrowser';

import { FirstPaintContext } from './FirstPaintContext';
import { FirstPaintProviderProps } from './FirstPaintProvider.d';

const getSSRDataSpan = (): HTMLSpanElement => {
  return document.getElementById('is-ssr');
};

export function FirstPaintProvider({
  children,
}: FirstPaintProviderProps): JSX.Element {
  const [firstPaint, setFirstPaint] = useState(() => {
    const span = isBrowser() ? getSSRDataSpan() : { dataset: { ssr: 'true' } };
    const render = span.dataset.ssr === 'true';
    return render;
  });

  const router = useRouter();

  useEffect(() => {
    if (firstPaint) {
      setFirstPaint(s => {
        const el = getSSRDataSpan();
        el.dataset.ssr = 'false';
        return !s;
      });
    }
  }, [router.asPath]);

  return (
    <FirstPaintContext.Provider value={{ firstPaint }}>
      {children}
    </FirstPaintContext.Provider>
  );
}
