import { createContext } from 'react';

export type ConversionContextInterface = {
  utmParams: () => void;
  clearUTMParams: () => void;
};

const ConversionContext = createContext<ConversionContextInterface>({
  utmParams: () => null,
  clearUTMParams: () => null,
});

export default ConversionContext;
